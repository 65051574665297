// src/components/Login.js
import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import '../styles/global.css';
import Captcha from './captcha';
import { SITE_URL, API_URL, GROUP_TELEGRAM } from './config';
import { UserContext } from '../context/UserContext';
import twitterAvatar from '../assets/images/img.png';
import TelegramButton from './TelegramButton';
import video5 from '../assets/vids/sexandfortnite.mp4';

const Login = () => {
  const [message, setMessage] = useState(null);
  const [captchaData, setCaptchaData] = useState(null);
  const navigate = useNavigate();
  const { user, token, setUser, setToken } = useContext(UserContext);

  useEffect(() => {
    document.body.classList.add('page-login');
    return () => {
      document.body.classList.remove('page-login');
    };
  }, []);

  useEffect(() => {
    if (user && token) {
      navigate('/dashboard');
    }
  }, [user, token, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!captchaData || !captchaData.captcha_id || !captchaData.captcha_input) {
      setMessage({ type: 'error', text: 'Please complete the captcha.' });
      setTimeout(() => setMessage(null), 3000);
      return;
    }

    const formData = new FormData(e.target);
    const data = {
      username: formData.get('username'),
      password: formData.get('password'),
      captcha: captchaData.captcha_input,
      captcha_id: captchaData.captcha_id,
    };

    try {
      const response = await fetch(`${API_URL}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(data),
      });


      const result = await response.json();

      if (response.ok) {
        setToken(result.access_token);
        setUser(result.user);
        setMessage({ type: 'success', text: 'Login successful! Redirecting...' });
        setTimeout(() => {
          setMessage(null);
          navigate('/dashboard');
        }, 2000);
      } else {
        setMessage({ type: 'error', text: result.msg || 'Login error.' });
        setTimeout(() => setMessage(null), 3000);
      }
    } catch (error) {
      console.error('Error during login:', error);
      setMessage({ type: 'error', text: 'Error connecting to the server.' });
      setTimeout(() => setMessage(null), 3000);
    }
  };

  const handleCaptchaData = (data) => {
    setCaptchaData(data);
  };

  return (
    <div className="page-container">
      <header className="site-header">
        <a href={SITE_URL} className="logo">LeakWorld</a>
        <nav className="main-nav">
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/register">Register</Link></li>
          </ul>
        </nav>
      </header>

      <section className="hero-section">
        <div className="hero-content">
          <h1>Exclusive Premium Teen Leaks Content</h1>
          <p>Access now and enjoy top-tier curated content just for you.</p>
          <div className="hero-buttons">
            <a href={GROUP_TELEGRAM} target="_blank" rel="noopener noreferrer" className="cta-button">Join Our Group</a>
            <a href="#previews" className="previews-button">Previews</a>
          </div>
        </div>
      </section>

      <div className="container">
        <div className="telegram-button-container">
          <TelegramButton />
        </div>
        <div className="content-wrapper">
          <div className="twitter-container">
            <div className="twitter">
              <button type="button" className="x-back" onClick={() => navigate(-1)}>←</button>
              <div className="twitter-buttons">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://twitter.com/intent/tweet?text=caldodepollo:%20${encodeURIComponent(SITE_URL)}`}
                >
                  <button className="x-post">Share</button>
                </a>
              </div>
            </div>
            <div className="twitter-inline">
              <div
                className="twitter-avatar"
                style={{
                  backgroundImage: `url(${twitterAvatar})`,
                }}
              ></div>
              <div className="twitter-text">
                <h3>#lxli #caldodepollo #teenleaks </h3>
                <h3>
                  <a
                    href={SITE_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="site-link"
                  >
                    {SITE_URL}
                  </a>
                </h3>
              </div>
            </div>
          </div>

          <div className="form-container">
            <h2>Welcome Back!</h2>
            <p>Log in now to access the most exclusive premium teen leaks content.</p>
            <form onSubmit={handleSubmit} id="loginForm">
              <input type="text" name="username" placeholder="Username" required />
              <input type="password" name="password" placeholder="Password" required />
              <Captcha onCaptchaData={handleCaptchaData} />
              <button type="submit" className="login-button">Login</button>
            </form>

            {message && (
              <div className={`message-container ${message.type === 'success' ? 'success-message' : 'error-message'}`}>
                {message.text}
              </div>
            )}

            <p>
              Don't have an account? <Link to="/register">Register here</Link>.
            </p>
          </div>
        </div>
      </div>

      <section id="previews" className="preview-section">
        <h2>Previews</h2>
        <video src={video5} controls muted loop className="preview-video"></video>
        <div className="preview-cta">
          <p>
            Don't miss out on more exclusive previews. <Link to="/register">Register now</Link> and unlock everything!
          </p>
        </div>
      </section>
    </div>
  );
};

export default Login;

// src/components/GetInvites.js
import React, { useContext, useState } from 'react';
import '../styles/dashboard.css';
import { UserContext } from '../context/UserContext';
import { SITE_URL } from './config';

const GetInvites = () => {
  const { user } = useContext(UserContext); // Access UserContext
  const [message, setMessage] = useState(null);

  if (!user) {
    return <div>Loading...</div>; // Show loading message while user data is not available
  }

  // Construct the invite link using the user's invite code
  const inviteLink = `${SITE_URL}/register?invite=${user.invite_code}`;

  // Function to handle copying the invite link to the clipboard
  const handleCopy = () => {
    navigator.clipboard.writeText(inviteLink)
      .then(() => setMessage({ type: 'success', text: 'Link copied to clipboard!' }))
      .catch(err => {
        console.error("Failed to copy text:", err);
        setMessage({ type: 'error', text: 'Failed to copy the link.' });
      });
    setTimeout(() => setMessage(null), 3000); // Clear message after 3 seconds
  };

  return (
    <div className="tier-container">
      <div className="card">
        <h3 className="centermid">
          You have <span className="color">{user.invites_count}</span> invitations. You need <span className="color">25</span> invitations to access <span className="color">Tier 1</span>.
        </h3>

        <h3 className="centermid" style={{ marginTop: '20px' }}>Your Invitation Link</h3>
        <input
          type="text"
          readOnly
          id="inviteLink"
          value={inviteLink}
          onClick={(e) => e.target.select()}
        />
        <button
          className="copyButton"
          onClick={handleCopy}
        >
          Copy Link
        </button>

        {message && (
          <div
            className={`message-container ${message.type === 'success' ? 'success-message' : 'error-message'}`}
          >
            {message.text}
          </div>
        )}

        <h4 className="centermid">
          Share your invite link with your friends, or <span className="green">spam your invitation</span> on social media platforms like <span className="color">Twitter</span>, <span className="color">Reddit</span>, <span className="color">Snapchat</span>, <span className="color">Instagram</span>, <span className="color">Facebook</span>, and others using relevant keywords.
        </h4>


        {/* Enhanced Twitter Post Button */}
        <div style={{ marginTop: '30px', textAlign: 'center' }}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://twitter.com/intent/tweet?text=hey%20caldodepollo%20${encodeURIComponent(inviteLink)}`}
          >
            <button className="twitter-post-button">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-twitter"
                viewBox="0 0 16 16"
                style={{ marginRight: '8px' }}
              >
                <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.533 6.533 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.084.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.864.115 3.23 3.23 0 0 1-.617-.058 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
              </svg>
              Post on Twitter
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default GetInvites;

// src/components/Tier.js
import React, { useContext, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { tiers } from './tiersData';
import '../styles/dashboard.css';
import { UserContext } from '../context/UserContext';
import { TELEGRAM_LINK } from './config';

const Tier = () => {
  const { id } = useParams();
  const tier = tiers.find((t) => t.id === parseInt(id));
  const { user } = useContext(UserContext);

  // Verifica se o Tier existe
  if (!tier) {
    return <div>Tier not found</div>;
  }

  // Controle de vídeos revelados
  const [revealedVideos, setRevealedVideos] = useState(
    Array(tier.videos.length).fill(false)
  );

  const handleReveal = (index) => {
    setRevealedVideos((prevState) => {
      const newState = [...prevState];
      newState[index] = true;
      return newState;
    });
  };

  if (!user) {
    return <div>Loading...</div>;
  }

  const hasEnoughInvites = user.invites_count >= tier.inviteThreshold;

  return (
    <div className="tier-container">
      {/* Informações do Tier */}
      <div className="tierCard">
        <h3>
          <span className={tier.className}>{tier.name}</span>
          <br />
          {tier.price} or {tier.invites} invites
          <br />
          <br />
          <span className={tier.className}>{tier.description}</span>
        </h3>
        <div className="tier-buttons">
          <Link to={`/tier/${tier.id}`}>
            <button className="tier">{tier.name}</button>
          </Link>
          <a href={TELEGRAM_LINK} target="_blank" rel="noopener noreferrer">
            <button className="access">Instant Access</button>
          </a>
          <Link to="/getinvites">
            <button className="invite">Invite Now</button>
          </Link>
        </div>
      </div>

      {/* Conteúdo do Usuário */}
      <div className="card">
        {hasEnoughInvites ? (
          <>
            <h3 className="centermid">
              Congratulations, <span className="color">{user.username}</span>! You have{' '}
              <span className="color">{user.invites_count}</span> invites!
            </h3>
            <h3 className="centermid">Welcome to {tier.name} Exclusive Content</h3>
            <br />
            <h3 className="centermid">
              Here's a small sample of the exclusive content we offer in {tier.name}.
              The full package contains over <span className="color">{tier.description}</span>
            </h3>
            {/* Pré-visualizações de Vídeos */}
            <div className="preview-container">
              {tier.videos.map((video, index) => (
                <div className="video-card" key={index}>
                  <div className="video-wrapper">
                    <video
                      controls
                      className={!revealedVideos[index] ? 'blurred-video' : ''}
                    >
                      <source src={video.src} type="video/mp4" />
                      Your browser does not support the video element.
                    </video>
                    {!revealedVideos[index] && (
                      <div
                        className="spoiler-overlay"
                        onClick={() => handleReveal(index)}
                        role="button"
                        tabIndex={0}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter' || e.key === ' ') {
                            handleReveal(index);
                          }
                        }}
                        aria-label="Click to reveal the video"
                      >
                        <span className="spoiler-text">🔒 Click to Reveal</span>
                      </div>
                    )}
                  </div>
                  <p>{video.label}</p>
                </div>
              ))}
            </div>
          </>
        ) : (
          <>
            <h3 className="centermid">
              <span className="color">{user.username}</span>, you don't have enough invites to access{' '}
              <span className="color">{tier.name}</span>. You have{' '}
              <span className="color">{user.invites_count}</span> invites.
            </h3>
            <br />
            <h3 className="centermid">
              You need <span className="color">{tier.inviteThreshold} invites</span> to access this
              tier. If you have enough invites, refresh the page to automatically unlock.
            </h3>
            <h3 className="centermid">To check your invites, click here:</h3>
            <Link to="/invites">
              <button className="invite">Invite Count</button>
            </Link>
          </>
        )}
        <br />
        <h3 className="centermid">
          To unlock all content instantly, you can purchase access now.
        </h3>
        <a href={TELEGRAM_LINK} target="_blank" rel="noopener noreferrer">
          <button className="access">Buy Now</button>
        </a>
      </div>

      {/* Cartão de Contagem de Convites */}
      <div className="card">
        <h3 className="centermid">If you want to check your invites, click here:</h3>
        <Link to="/invites">
          <button className="invite">Invite Count</button>
        </Link>
      </div>
    </div>
  );
};

export default Tier;

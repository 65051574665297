// src/components/tiersData.js
export const tiers = [
  {
    id: 1,
    name: 'Tier 1',
    price: '$25',
    invites: 20,
    description: '[ 60 GB MEGA LINK ] CP is all 17 (all age 12-17) (with 3000+videos)',
    link: '/tier/1',
    className: 'tier1',
    inviteThreshold: 20,
    videos: [
      {
        src: 'https://ik.imagekit.io/zl40edca8/BW1896.mp4?updatedAt=1731978589864',
        label: 'Exclusive Preview 1',
      },
      {
        src: 'https://ik.imagekit.io/zl40edca8/BW1670_part1b.mp4?updatedAt=1731978589727',
        label: 'Exclusive Preview 2',
      },
      {
        src: 'https://ik.imagekit.io/zl40edca8/962eba4c-721e-40f9-ad48-6f6315e2c34d.mp4?updatedAt=1731978586501',
        label: 'Exclusive Preview 3',
      },
      {
        src: 'https://ik.imagekit.io/zl40edca8/5631acdd-77bb-48bf-81d2-e0193c6f9883.mp4?updatedAt=1731978586490',
        label: 'Exclusive Preview 4',
      },
    ],
  },
  {
    id: 2,
    name: 'Tier 2',
    price: '$35',
    invites: 55,
    description: '[ 115 GB ] CP T33n MEGA LINK age 5-17(contain t33n, tod, mom and son, dad and daughter, incest, bro and sister, rap3 and much more) (7000+ videos)',
    link: '/tier/2',
    className: 'tier2',
    inviteThreshold: 55,
    videos: [
      {
        src: 'https://ik.imagekit.io/zl40edca8/_136.mp4?updatedAt=1731978585621',
        label: 'Exclusive Preview 1',
      },
      {
        src: 'https://ik.imagekit.io/zl40edca8/0a0avideo_2019-12-14_10-16-59.mp4?updatedAt=1731978585503',
        label: 'Exclusive Preview 2',
      },
      {
        src: 'https://ik.imagekit.io/zl40edca8/083.mp4?updatedAt=1731978583115',
        label: 'Exclusive Preview 3',
      },
      {
        src: 'https://ik.imagekit.io/zl40edca8/6e79fc43-c264-44f4-95bd-b812601ed19e.mp4?updatedAt=1731978582953',
        label: 'Exclusive Preview 4',
      },
    ],
  },
  {
    id: 3,
    name: 'Tier 3',
    price: '$60',
    invites: 95,
    description: '[ 246 GB ] CP totally different from the 115 GB one(all age 5-17, with highly clearer menu, contain more incest) (16893+ videos)',
    link: '/tier/3',
    className: 'tier3',
    inviteThreshold: 95,
    videos: [
      {
        src: 'https://ik.imagekit.io/zl40edca8/066.mp4?updatedAt=1731978582714',
        label: 'Exclusive Preview 1',
      },
      {
        src: 'https://ik.imagekit.io/zl40edca8/87.mp4?updatedAt=1731978582633',
        label: 'Exclusive Preview 2',
      },
      {
        src: 'https://ik.imagekit.io/zl40edca8/91cd4cda349a70959c71001b51351d6e.mp4?updatedAt=1731978582467',
        label: 'Exclusive Preview 3',
      },
      // Optional: Add a fourth video or reuse an existing link
      {
        src: 'https://ik.imagekit.io/zl40edca8/89.mp4?updatedAt=1731978582180',
        label: 'Exclusive Preview 4',
      },
    ],
  },
  {
    id: 4,
    name: 'Tier 4',
    price: '$77',
    invites: 150,
    description: '[ 1 TB MEGA LINK ] CP Totally different from the 246 GB one Everything above and 1 TB of t33n and cp content age 5-17 [t33n, tod, mom and son, rap3, cp, incest and much more] (VIP group access) (44171+ videos)',
    link: '/tier/4',
    className: 'tier4',
    inviteThreshold: 150,
    videos: [
      {
        src: 'https://ik.imagekit.io/zl40edca8/87%20(1).mp4?updatedAt=1731978582081',
        label: 'Exclusive Preview 1',
      },
      {
        src: 'https://ik.imagekit.io/zl40edca8/024-4_watermarked.mp4?updatedAt=1731978581835',
        label: 'Exclusive Preview 2',
      },
      // Optional: Add more videos or reuse existing links
      {
        src: 'https://ik.imagekit.io/zl40edca8/BW1896.mp4?updatedAt=1731978589864',
        label: 'Exclusive Preview 3',
      },
      {
        src: 'https://ik.imagekit.io/zl40edca8/BW1670_part1b.mp4?updatedAt=1731978589727',
        label: 'Exclusive Preview 4',
      },
    ],
  },
  {
    id: 5,
    name: 'Tier 5',
    price: '$160',
    invites: 250,
    description: '[ 4 TB MEGA LINK ] CP totally different from the 1 TB one',
    link: '/tier/5',
    className: 'tier5',
    inviteThreshold: 250,
    videos: [
      {
        src: 'https://ik.imagekit.io/zl40edca8/962eba4c-721e-40f9-ad48-6f6315e2c34d.mp4?updatedAt=1731978586501',
        label: 'Exclusive Preview 1',
      },
      {
        src: 'https://ik.imagekit.io/zl40edca8/5631acdd-77bb-48bf-81d2-e0193c6f9883.mp4?updatedAt=1731978586490',
        label: 'Exclusive Preview 2',
      },
      {
        src: 'https://ik.imagekit.io/zl40edca8/_136.mp4?updatedAt=1731978585621',
        label: 'Exclusive Preview 3',
      },
      {
        src: 'https://ik.imagekit.io/zl40edca8/0a0avideo_2019-12-14_10-16-59.mp4?updatedAt=1731978585503',
        label: 'Exclusive Preview 4',
      },
    ],
  },
  {
    id: 6,
    name: 'Tier 6',
    price: '$450',
    invites: 800,
    description: '[ 15 TB MEGA LINK ] CP totally different from the 4 TB one',
    link: '/tier/6',
    className: 'tier6',
    inviteThreshold: 800,
    videos: [
      {
        src: 'https://ik.imagekit.io/zl40edca8/083.mp4?updatedAt=1731978583115',
        label: 'Exclusive Preview 1',
      },
      {
        src: 'https://ik.imagekit.io/zl40edca8/6e79fc43-c264-44f4-95bd-b812601ed19e.mp4?updatedAt=1731978582953',
        label: 'Exclusive Preview 2',
      },
      {
        src: 'https://ik.imagekit.io/zl40edca8/066.mp4?updatedAt=1731978582714',
        label: 'Exclusive Preview 3',
      },
      {
        src: 'https://ik.imagekit.io/zl40edca8/87.mp4?updatedAt=1731978582633',
        label: 'Exclusive Preview 4',
      },
    ],
  },
];

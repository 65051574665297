// src/components/TelegramButton.js
import React from 'react';
import '../styles/telegramButton.css'; // Estilo específico para o botão
import { TELEGRAM_LINK } from './config'; // Importando o link do Telegram

const TelegramButton = () => {
  return (
    <a
      href={TELEGRAM_LINK}
      target="_blank"
      rel="noopener noreferrer"
      className="telegram-button"
      aria-label="Acesse nosso Telegram"
    >
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/8/82/Telegram_logo.svg"
        alt="Telegram"
        className="telegram-icon"
      />
      <span className="telegram-text">Telegram</span>
    </a>
  );
};

export default TelegramButton;

// src/components/Layout.js
import React, { useState, useContext } from 'react';
import '../styles/dashboard.css';
import { Outlet, Link, Navigate, useLocation } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import { SITE_URL, TELEGRAM_LINK, GROUP_TELEGRAM } from './config';
import { FaTelegramPlane, FaShoppingCart } from 'react-icons/fa';

const Layout = () => {
  const { user, isLoading, setUser, setToken } = useContext(UserContext);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <Navigate to="/register" replace />;
  }

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const handleLogout = () => {
    setUser(null);
    setToken(null);
    localStorage.removeItem('user');
    localStorage.removeItem('token');
  };

  return (
    <div className="dashboard-container">
      <nav>
        <div className="navbar">
          <div className="nav-container">
            <div className="logo">
              {/* Logo (opcional) */}
            </div>
            <div className="hamburger_menu">
              <button
                className={`menu__btn${isMenuOpen ? ' open' : ''}`}
                onClick={handleMenuToggle}
                aria-label="Toggle Menu"
              >
                <span></span>
              </button>
              <ul className={`menu__box ${isMenuOpen ? 'open' : ''}`}>
                <button className="menu__close-btn" onClick={closeMenu} aria-label="Close Menu">
                  ×
                </button>
                <div className="profileData">
                  <h3>Username: {user.username}</h3>
                  <h4>ID: {user.id}</h4>
                  <h4>Tier: {user.currentTier ? user.currentTier.name : 'Locked'}</h4>
                  <h4>Invites: {user.invites_count}</h4>
                  <Link to="/getinvites" onClick={closeMenu}>
                    <button className="invite">Invite Now</button>
                  </Link>
                </div>
                <li>
                  <Link className="menu__item" to="/dashboard" onClick={closeMenu}>
                    Dashboard
                  </Link>
                </li>
                <li>
                  <Link className="menu__item" to="/preview" onClick={closeMenu}>
                    Free Preview
                  </Link>
                </li>
                <li>
                  <Link className="menu__item" to="/get" onClick={closeMenu}>
                    Get more video
                  </Link>
                </li>
                <li>
                  <Link className="menu__item" to="/invites" onClick={closeMenu}>
                    Invites Counting
                    <span
                      style={{
                        backgroundColor: 'var(--accent-color)',
                        padding: '5px 10px',
                        borderRadius: '60px',
                        color: '#fff',
                        marginLeft: '10px',
                        display: 'inline-block',
                      }}
                    >
                      {user.invites_count}
                    </span>
                  </Link>
                </li>
                <li>
                  <Link className="menu__item" to="/getinvites" onClick={closeMenu}>
                    Get fast invites
                  </Link>
                </li>
                {[...Array(6)].map((_, index) => (
                  <li key={index + 1}>
                    <Link
                      className="menu__item"
                      to={`/tier/${index + 1}`}
                      onClick={closeMenu}
                    >
                      Tier {index + 1}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="logout-container">
              <button onClick={handleLogout} className="logout-button" aria-label="Logout">
                Logout
              </button>
            </div>
          </div>
        </div>
      </nav>

      <div className="bottom-buttons">
        <a href={TELEGRAM_LINK} target="_blank" rel="noopener noreferrer">
          <button className="buyNow" aria-label="Buy Now">
            <FaShoppingCart className="button-icon" /> Buy <br /> Now!
          </button>
        </a>
        <a href={GROUP_TELEGRAM} target="_blank" rel="noopener noreferrer">
          <button className="groupTelegram" aria-label="Join Telegram Group">
            <FaTelegramPlane className="button-icon" /> Join <br /> Group
          </button>
        </a>
      </div>

      <div className="info-container">
        <Outlet key={location.pathname} />
      </div>
    </div>
  );
};

export default Layout;

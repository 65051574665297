// src/components/Get.js
import React, { useContext } from 'react';
import '../styles/dashboard.css';
import { UserContext } from '../context/UserContext';
import { Link } from 'react-router-dom';
import { TELEGRAM_LINK } from './config';
import { tiers } from './tiersData';

const Get = () => {
  const { user } = useContext(UserContext);

  // Determina o Tier atual do usuário
  const getTier = (invitesCount) => {
    const sortedTiers = [...tiers].sort((a, b) => b.inviteThreshold - a.inviteThreshold);
    for (let tier of sortedTiers) {
      if (invitesCount >= tier.inviteThreshold) {
        return tier;
      }
    }
    return null;
  };

  const currentUserTier = getTier(user.invites_count);

  return (
    <div className="tier-container">
      {/* Saudação e Introdução */}
      <div className="card">
        <h3 className="centermid">
          Hello, <span className="color">{user.username}</span>!
        </h3>
        <h3 className="centermid" style={{ marginTop: '5px' }}>
          You can gain access to more videos either by
          <span className="color"> purchasing instant access</span>
          or
          <span className="color"> inviting others</span> to join.
        </h3>
        {currentUserTier ? (
          <h4 className="centermid" style={{ marginTop: '10px' }}>
            Your current tier: <span className="color">{currentUserTier.name}</span> <br />
            Invites: <span className="color">{user.invites_count}</span>
          </h4>
        ) : (
          <h4 className="centermid" style={{ marginTop: '10px' }}>
            You haven't unlocked any tier yet.
          </h4>
        )}
      </div>

      {/* Listagem dos Tiers */}
      {tiers.map((tier) => (
        <div key={tier.id} className="tierCard">
          <h3>
            <span className={tier.className}>{tier.name}</span><br />
            {tier.price} or {tier.invites} invites
            <br /><br />
            <span className={tier.className}>
              {tier.description.split('\n').map((line, index) => (
                <React.Fragment key={index}>
                  {line}<br />
                </React.Fragment>
              ))}
            </span>
          </h3>
          <div className="tier-buttons">
            <Link to={`/tier/${tier.id}`}>
              <button className="tier">{tier.name}</button>
            </Link>
            <a href={TELEGRAM_LINK} target="_blank" rel="noopener noreferrer">
              <button className="access">Instant Access</button>
            </a>
            <Link to="/getinvites">
              <button className="invite">Invite Now</button>
            </Link>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Get;

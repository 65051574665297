// src/components/RegistrationStats.js
import React, { useState, useEffect } from 'react';
import { API_URL } from './config';
import '../styles/allusers.css';

const RegistrationStats = () => {
  const [stats, setStats] = useState({
    registrationsPerHour: Array(24).fill(0),
    registrationsLastWeek: 0,
    registrationsLastMonth: 0,
    registrationsToday: 0,
    registrationsPerDayOfWeek: Array(7).fill(0),
    bestDay: null,
    error: null,
    loading: true,
  });

  const [lastUpdate, setLastUpdate] = useState(new Date());

  const fetchUsers = async () => {
    try {
      const response = await fetch(`${API_URL}/allusers`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });

      const result = await response.json();

      if (response.ok) {
        calculateStats(result.users);
        setLastUpdate(new Date());
      } else {
        setStats((prev) => ({ ...prev, error: result.msg || 'Error fetching users.', loading: false }));
      }
    } catch (err) {
      console.error('Error fetching users:', err);
      setStats((prev) => ({ ...prev, error: 'Server connection error.', loading: false }));
    }
  };

  const calculateStats = (users) => {
    const now = new Date();
    const registrationsPerHour = Array(24).fill(0);
    let registrationsLastWeek = 0;
    let registrationsLastMonth = 0;
    let registrationsToday = 0;
    const registrationsPerDayOfWeek = Array(7).fill(0);

    const startOfToday = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const oneWeekAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
    const oneMonthAgo = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);

    users.forEach((user) => {
      const registeredAt = new Date(user.registered_at);

      if (!isNaN(registeredAt)) {
        const hour = registeredAt.getHours();
        registrationsPerHour[hour] += 1;

        if (registeredAt >= oneWeekAgo) {
          registrationsLastWeek += 1;
        }

        if (registeredAt >= oneMonthAgo) {
          registrationsLastMonth += 1;
        }

        if (registeredAt >= startOfToday) {
          registrationsToday += 1;
        }

        const dayOfWeek = registeredAt.getDay();
        registrationsPerDayOfWeek[dayOfWeek] += 1;
      }
    });

    const maxRegistrations = Math.max(...registrationsPerDayOfWeek);
    const bestDayIndex = registrationsPerDayOfWeek.indexOf(maxRegistrations);
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const bestDay = daysOfWeek[bestDayIndex];

    setStats({
      registrationsPerHour,
      registrationsLastWeek,
      registrationsLastMonth,
      registrationsToday,
      registrationsPerDayOfWeek,
      bestDay,
      error: null,
      loading: false,
    });
  };

  useEffect(() => {
    fetchUsers();
    const interval = setInterval(fetchUsers, 30000); // Refresh every 30 seconds
    return () => clearInterval(interval);
  }, []);

  if (stats.error) {
    return <div className="error-message">{stats.error}</div>;
  }

  if (stats.loading) {
    return <div className="loading-message">Loading registration statistics...</div>;
  }

  const currentHour = new Date().getHours();
  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  return (
    <div className="registration-stats-container">
      <h2>Registration Statistics</h2>
      <div className="info-header">
        <p><strong>Current Date/Time:</strong> {lastUpdate.toLocaleString()}</p>
        <p><strong>Note:</strong> All hours displayed are based on your local time zone.</p>
      </div>

      {/* Overview Statistics */}
      <div className="stats-overview">
        <div className="stat-card">
          <h3>Last Week</h3>
          <p>{stats.registrationsLastWeek} Registrations</p>
        </div>
        <div className="stat-card">
          <h3>Last Month</h3>
          <p>{stats.registrationsLastMonth} Registrations</p>
        </div>
        <div className="stat-card">
          <h3>Today</h3>
          <p>{stats.registrationsToday} Registrations</p>
        </div>
      </div>

      {/* Registrations Per Hour */}
      <div className="stat-section">
        <h3>Registrations Per Hour</h3>
        <p className="section-subtitle">
          These statistics show how many users registered during each hour of the day (from 00:00 to 23:00).
        </p>
        <div className="registrations-per-hour">
          {stats.registrationsPerHour.map((count, hour) => (
            <div
              key={hour}
              className={`hour-block ${hour === currentHour ? 'current-hour' : ''}`}
            >
              <span className="hour-label">{hour}:00</span>
              <span className="hour-count">{count}</span>
            </div>
          ))}
        </div>
      </div>

      {/* Registrations Per Day of the Week */}
      <div className="stat-section">
        <h3>Registrations Per Day of the Week</h3>
        <div className="registrations-per-day">
          {stats.registrationsPerDayOfWeek.map((count, dayIndex) => (
            <div
              key={dayIndex}
              className={`day-block ${stats.bestDay === daysOfWeek[dayIndex] ? 'best-day' : ''}`}
            >
              <span className="day-label">{daysOfWeek[dayIndex]}</span>
              <span className="day-count">{count}</span>
            </div>
          ))}
        </div>
        {stats.bestDay && (
          <div className="best-day-info">
            <strong>Best Day:</strong> {stats.bestDay} (with {Math.max(...stats.registrationsPerDayOfWeek)} registrations)
          </div>
        )}
      </div>
    </div>
  );
};

export default RegistrationStats;

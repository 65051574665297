// src/components/Captcha.js
import React, { useEffect, useState } from 'react';
import { API_URL } from './config';
import { useNavigate } from 'react-router-dom';

const Captcha = ({ onCaptchaData }) => {
    const [num1, setNum1] = useState(null);
    const [num2, setNum2] = useState(null);
    const [captchaId, setCaptchaId] = useState(null);
    const [loading, setLoading] = useState(true);
    const [userInput, setUserInput] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        fetchCaptcha();
    }, []);

    const fetchCaptcha = () => {
        fetch(`${API_URL}/captcha`, {
            method: 'GET',
            credentials: 'include',
        })
            .then(response => {
                if (response.status === 403) {
                    navigate('/unvaliable');
                    throw new Error('Access blocked');
                }
                return response.json();
            })
            .then(data => {
                setNum1(data.num1);
                setNum2(data.num2);
                setCaptchaId(data.captcha_id);
                setLoading(false);
                onCaptchaData({
                    captcha_id: data.captcha_id,
                    captcha_input: '',
                });
            })
            .catch(error => {
                if (error.message !== 'Access blocked') {
                    console.error('Error fetching captcha:', error);
                }
                setLoading(false);
            });
    };

    const refreshCaptcha = () => {
        setLoading(true);
        fetchCaptcha();
    };

    const handleChange = (e) => {
        const value = e.target.value;
        setUserInput(value);
        onCaptchaData({
            captcha_id: captchaId,
            captcha_input: value,
        });
    };

    return (
        <div className="captcha-container">
            {loading ? (
                <p>Loading captcha...</p>
            ) : (
                <>
                    <div className="captcha-top">
                        <div className="captcha-question">
                            {num1} + {num2} = ?
                        </div>
                        <button
                            type="button"
                            className="reroll-button"
                            onClick={refreshCaptcha}
                            title="Reload Captcha"
                        >
                            ↻
                        </button>
                    </div>
                    <input
                        type="text"
                        name="captcha"
                        placeholder="Enter the answer"
                        onChange={handleChange}
                        required
                    />
                </>
            )}
        </div>
    );
};

export default Captcha;

// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './components/login';
import Register from './components/register';
import Dashboard from './components/dashboard';
import Get from './components/Get';
import GetInvites from './components/GetInvites';
import Layout from './components/layout';
import Preview from './components/Preview';
import Invites from './components/Invites';
import AllUsers from './components/AllUsers';
import Tier from './components/Tier'; // Importe o componente genérico


const App = () => {
  return (
    <Router>
      <Routes>
        {/* Rotas Públicas */}

        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Navigate to="/" replace />} />
        <Route path="/register" element={<Register />} />
        <Route path="/allusers" element={<AllUsers />} />

        {/* Rotas Protegidas com Layout */}
        <Route element={<Layout />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/get" element={<Get />} />
          <Route path="/getinvites" element={<GetInvites />} />
          <Route path="/invites" element={<Invites />} />
          <Route path="/preview" element={<Preview />} />
          {/* Rota dinâmica para os Tiers */}
          <Route path="/tier/:id" element={<Tier />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default App;

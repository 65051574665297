// src/context/UserContext.js
import React, { createContext, useState, useEffect } from 'react';

// Cria o contexto
export const UserContext = createContext();

// Provedor do contexto
export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null); // Nenhum usuário autenticado inicialmente
  const [token, setToken] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // Estado de carregamento

  // Carregar token e usuário do localStorage ao iniciar
  useEffect(() => {
    const savedToken = localStorage.getItem('token');
    const savedUser = localStorage.getItem('user');
    if (savedToken && savedUser) {
      setToken(savedToken);
      setUser(JSON.parse(savedUser));
    }
    setIsLoading(false); // Finaliza o carregamento
  }, []);

  // Atualizar o localStorage quando token ou usuário mudar
  useEffect(() => {
    if (token) {
      localStorage.setItem('token', token);
    } else {
      localStorage.removeItem('token');
    }

    if (user) {
      localStorage.setItem('user', JSON.stringify(user));
    } else {
      localStorage.removeItem('user');
    }
  }, [token, user]);

  return (
    <UserContext.Provider value={{ user, setUser, token, setToken, isLoading }}>
      {children}
    </UserContext.Provider>
  );
};

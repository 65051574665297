// src/components/Invites.js
import React, { useState, useEffect, useContext } from 'react';
import '../styles/dashboard.css';
import { UserContext } from '../context/UserContext';
import { FaUserFriends } from 'react-icons/fa';

const Invites = () => {
  const { user } = useContext(UserContext);
  const [invites, setInvites] = useState([]);

  if (!user) {
    return <div>Loading...</div>;
  }

  // Function to generate a fake invite
  const generateFakeInvite = () => {
    const inviterUsername = generateRandomString(8);
    const inviteeUsername = generateRandomString(8);
    const invitesCount = generateRandomInvites(10, 150);
    const tierUnlocked = getTier(invitesCount);
    return { inviterUsername, inviteeUsername, invitesCount, tierUnlocked };
  };

  // Helper function to generate random strings
  const generateRandomString = (length) => {
    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  };

  // Helper function to generate random invite counts
  const generateRandomInvites = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  // Function to determine tier based on invite count
  const getTier = (invitesCount) => {
    if (invitesCount >= 800) return 6;
    if (invitesCount >= 250) return 5;
    if (invitesCount >= 150) return 4;
    if (invitesCount >= 95) return 3;
    if (invitesCount >= 55) return 2;
    if (invitesCount >= 25) return 1;
    return 0;
  };

  // Function to display an invite in the list
  const displayInvite = (invite) => {
    setInvites((prevInvites) => [invite, ...prevInvites]);
  };

  // Simulate the generation of invites every 2 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      const fakeInvite = generateFakeInvite();
      displayInvite(fakeInvite);
    }, 2000);

    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, []);

  return (
    <div className="invites-page">
      {/* Seção fixada de convites totais */}
      <div className="total-invites-fixed">
        <div className="invites-info">
          <FaUserFriends className="invites-icon" />
          <div className="invites-text">
            <h2>You have</h2>
            <h1>{user.invites_count} Invites</h1>
          </div>
        </div>
      </div>

      {/* Lista de convites */}
      <div className="invites-container" id="invites-container">
        {invites.map((invite, index) => (
          <div key={index} className="invite-card">
            <p>
              <span className="color">{invite.inviterUsername}</span> has invited{' '}
              <span className="color">{invite.inviteeUsername}</span> [{invite.invitesCount} invites]
            </p>
            {invite.tierUnlocked > 0 && (
              <p className="tier-unlocked">
                <span className="color">{invite.inviteeUsername}</span> unlocked{' '}
                <span className="color">Tier {invite.tierUnlocked}</span>!
              </p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Invites;